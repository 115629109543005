@keyframes pulse {
	50% {
    opacity: .4;
  }
}

.skeleton-line {
  background-color: var(--border) !important;
  border-radius: 3px !important;
  color: var(--border) !important;
  transform: scaleY(0.7);
  user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-box {
  background-color: var(--border) !important;
  color: var(--border) !important;
  user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-circle {
  background-color: var(--border) !important;
  border-radius: 1000px !important;
  color: var(--border) !important;
  user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-circle > *,
.skeleton-box > *,
.skeleton-line > * {
  opacity: 0 !important;
}


/* NOTE: For storybook snapshots */
.disable-animations .skeleton-circle,
.disable-animations .skeleton-line,
.disable-animations .skeleton-box {
  animation: unset !important;
}