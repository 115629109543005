html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: var(--font-inter);
}

.smooth-scroll {
  scroll-behavior: smooth;
}

#__next {
  display: flex;
  flex: 1;
}

a {
  transition: color .2s ease;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--contrast) !important;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--contrast), 0 0 5px var(--contrast) !important;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: var(--contrast) !important;
  border-left-color: var(--contrast) !important;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.active {
  font-weight: 500;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.drawer-overlay {
  position: fixed !important;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  position: fixed !important;
  inset: 0;
  background: white;
  z-index: 1000;
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.2);
}

.drawer-content.from-left {
  left: 0;
}

.drawer-content.from-right {
  right: 0;
}

@media screen and (min-width: 768px) {
  .drawer-content {
    inset: unset;
    top: 0;
    bottom: 0;
    width: 500px;
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: red;
  transform-origin: 0;
}

/* Used for editorial content (pages etc) */
.content {
  margin-bottom: 80px;
}

.content p {
  color: var(--color);
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

.content ul {
  margin: 10px 0;
  display: block;
}

.content li {
  display: list-item;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 15px;
}

.content a {
  text-decoration: underline;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.img-wrap:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
  border-radius: inherit;
  border: 1px solid #000000;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.landing-hero-image-wrapper {
  position: relative;
}
.landing-hero-image-wrapper::after {
  content: '';
  position: absolute;
  width: 70%;
  height: 50%;
  border-radius: 50%;
  bottom: 10%;
  left: 0;
  z-index: -1;
  background-color: #2d6936;
  filter: blur(90px);
  opacity: 0.9;
}

.landing-page-grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  gap: 24px;
}

.sale-page-grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  gap: 40px;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.popover-animation {
  animation: fadeIn .2s cubic-bezier(.455,.03,.515,.955) forwards;
}

.user-menu-animation {
  animation: fadeInFull .1s ease-in forwards;
}

.counter-input-animation {
  animation: fadeInFull .04s ease-in forwards;
}

.navigation-menu-animation {
  animation: navigation-menu_scaleIn .2s ease
}

@keyframes navigation-menu_scaleIn {
  0% {
      opacity: 0;
      transform: rotateX(-30deg) scale(.9)
  }

  to {
      opacity: 1;
      transform: rotateX(0deg) scale(1)
  }
}

@keyframes fadeIn {
  0% {
    opacity: .3
  }
  to {
    opacity: 1
  }
}

@keyframes fadeInFull {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@supports (((-webkit-backdrop-filter: saturate(180%) blur(5px)) or (backdrop-filter:saturate(180%) blur(20px)))) {
  .header::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    backface-visibility: hidden;
    perspective: 1000;
    z-index: -1;
    top: -1px;
    transform: translateZ(0)
  }
}