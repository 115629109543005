/* mimic the native viewport full width/height + flex */
/* https://github.com/tamagui/tamagui/blob/master/packages/core/reset.css */
body, #root, #__next {
  display: unset;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  font-family: Inter;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

li, select, input, button, [role="slider"], [role="listbox"] {
  outline: none;
}

p,
input,
textarea,
button,
ul,
ol,
li,
pre,
dialog {
  all: unset;
  box-sizing: border-box;
}

.is_Anchor:has(.is_Button) {
  text-decoration: none;
}

input {
  outline: none !important;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.table-row-with-onclick:hover {
  cursor: pointer;
  background-color: var(--subtlePale);
}